import React from "react";
import { Line } from "recharts";
import { LineChart } from "@uikit";
import { colors } from "@uikit/theme";
import pick from "lodash/pick";

const WindDirectionChart = ({ data, strokeWidth, timeframe, loading }) => {
  const windDirectionData = data.records.map((r) => ({
    ...pick(r, ["ts", "ts_month", "ts_year"]),
    winddirection_deg_wvt: parseFloat(r.winddirection_deg_wvt),
  }));

  const degToCardinalMap = {
    0: "N",
    90: "E",
    180: "S",
    270: "W",
    360: "N",
  };

  const ticks = [0, 90, 180, 270, 360];
  const degToCardinal = (tick) => degToCardinalMap[tick];

  const labelMap = {
    winddirection_deg_wvt: "Wind direction (deg)",
  };

  return (
    <LineChart
      title="Wind Direction"
      data={windDirectionData}
      leftYTicks={ticks}
      rightYTicks={ticks}
      labelMap={labelMap}
      timeframe={timeframe}
      leftYAxisLabel={"Degrees"}
      rightYAxisLabel={""}
      rightYTickFormatter={degToCardinal}
      legendContent={[{ color: colors.darkTurquoise, label: "Wind Direction" }]}
      loading={loading}
    >
      <Line
        type="linear"
        dataKey="winddirection_deg_wvt"
        stroke={colors.darkTurquoise}
        dot={{ stroke: colors.darkTurquoise, strokeWidth }}
        strokeWidth={strokeWidth}
        isAnimationActive={false}
      />
    </LineChart>
  );
};

WindDirectionChart.defaultProps = {
  data: {
    records: [],
  },
  timeframe: "day",
};

export default WindDirectionChart;
