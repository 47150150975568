export const colors = {
  solidDarkBlue: "#00008b",
  lightBlue: "#add8e6",
  deepSkyBlue: "#00B2EE",
  deepSkyLightBlue: "#4dd2ff",
  green: "#6fd9b2",
  red: "#f25555",
  darkGreen: "#007047",
  gold: "#d4af37",
  lightGold: "#e7c97c",
  orange: "#ffbb5e",
  darkTurquoise: "#009092",
  irishGreen: "#095b5e",
  bayGreen: "#188a7a",
  bayGreenLight: "#1d9c8a",
  yellow: "#ffff00",
  white: "#fff",
  cardWhite: "#eae8e8",
  gray: "#b4b4b4",
  lightGray: "#EDF2F7",
  shadow: "#050603",
  black: "#000",
};

export const theme = {
  colors,
  fonts: ["Poppins-Regular", "Poppins-Bold"],
};
