import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Flex } from "@chakra-ui/core";

const BaseLayout = ({ children }) => {
  return (
    <Flex
      overflowX={{ xs: "hidden", md: "auto" }}
      minH="100vh"
      flexDirection="column"
      flex={1}
    >
      <Header />
      {children}
      <Footer />
    </Flex>
  );
};

BaseLayout.propTypes = {};

export default BaseLayout;
