import React from "react";
import { Line } from "recharts";
import { LineChart } from "@uikit";
import { colors } from "@uikit/theme";
import pick from "lodash/pick";

const MaximumHumidityCharts = ({ data, strokeWidth, timeframe, loading }) => {
  const maximumHumidityData = data.records.map((r) =>
    pick(r, ["ts", "ts_month", "ts_year", "relhumidity_pc_max"])
  );

  const labelMap = {
    relhumidity_pc_max: "Max humidity %",
  };

  const yAxisOffset = 0.5;

  return (
    <LineChart
      title="Maximum Humidity"
      data={maximumHumidityData}
      labelMap={labelMap}
      timeframe={timeframe}
      leftYAxisLabel={"%"}
      rightYAxisLabel={""}
      leftYAxisDataKey={"relhumidity_pc_max"}
      rightYAxisDataKey={"relhumidity_pc_max"}
      legendContent={[{ color: colors.darkGreen, label: "Maximum Humidity" }]}
      rightYTickFormatter={(i) => i}
      domain={[`dataMin - ${yAxisOffset}`, `dataMax + ${yAxisOffset}`]}
      loading={loading}
    >
      <Line
        type="linear"
        dataKey="relhumidity_pc_max"
        stroke={colors.darkGreen}
        dot={{ stroke: colors.darkGreen, strokeWidth }}
        strokeWidth={strokeWidth}
        isAnimationActive={false}
      />
    </LineChart>
  );
};

MaximumHumidityCharts.defaultProps = {
  data: {
    records: [],
  },
  timeframe: "day",
};

export default MaximumHumidityCharts;
