import React from "react";
import { Flex, Text, Box } from "@chakra-ui/core";
import PropTypes from "prop-types";
import { colors } from "./theme";

const CustomTooltip = ({ title, content }) => (
  <Flex
    alignContent="center"
    backgroundColor="rgba(255, 255, 255, 0.95)"
    padding={4}
    borderWidth={2}
    borderRadius={8}
    flexDirection="column"
  >
    <Text
      as="b"
      color={colors.bayGreen}
      textAlign="center"
      py={1}
      fontSize={18}
    >
      {title}
    </Text>
    <Flex paddingTop={1} flexDirection="column">
      {content.map(({ label, value }) => (
        <Box>
          <Text as="b" fontWeight="600" fontSize={16}>
            {label}
          </Text>
          <Text as="span" fontSize={16}>
            {value}
          </Text>
        </Box>
      ))}
    </Flex>
  </Flex>
);

CustomTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  labelMap: PropTypes.shape({}).isRequired,
};

export default CustomTooltip;
