// Request
export const request = async (method, path, errorMessage) => {
  const API_URL = process.env.REACT_APP_API_URL || "http://localhost:4000";

  const response = await fetch(`${API_URL}${path}`, {
    method,
    headers: {
      Accept: "application/json",
      "Allow-Control-Allow-Origin": "*",
    },
  });

  if (!response.ok) {
    throw new Error(errorMessage);
  }

  return response.json();
};

// Numbers

export const numbers = [...Array(28).keys()];

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

export const getRandomFloat = (min, max) => {
  return (Math.random() * (max - min) + min).toFixed(2);
};

export const roundToMultiple = (n, v = 10) => {
  return Math.ceil(n / v) * v;
};
