import React from "react";
import { Line } from "recharts";
import { LineChart } from "@uikit";
import { colors } from "@uikit/theme";
import pick from "lodash/pick";

const RainChart = ({ data, strokeWidth, timeframe, loading }) => {
  const rainData = data?.records.map((r) =>
    pick(r, ["ts", "ts_month", "ts_year", "rain_in_total", "rain_mm_total"])
  );

  const mmToInches = (mm) => (mm / 25.4).toFixed(2);

  const labelMap = {
    rain_in_total: "Rain total (in)",
    rain_mm_total: "Rain total (mm)",
  };

  const noRainMmTotalInData = rainData
    ? rainData.every((r) => r.rain_mm_total === null)
    : [];

  return (
    <LineChart
      title="Total Rain"
      data={rainData}
      labelMap={labelMap}
      timeframe={timeframe}
      leftYAxisLabel={"mm"}
      rightYAxisLabel={"Inches"}
      leftYAxisDataKey={
        !noRainMmTotalInData ? "rain_mm_total" : "rain_in_total"
      }
      rightYAxisDataKey={
        !noRainMmTotalInData ? "rain_mm_total" : "rain_in_total"
      }
      rightYTickFormatter={mmToInches}
      leftYTickFormatter={(tick) => Number(tick).toLocaleString()}
      legendContent={[
        {
          color: colors.green,
          label: "Total Rain",
        },
      ]}
      loading={!data || loading}
    >
      <Line
        type="linear"
        dataKey="rain_mm_total"
        stroke={colors.green}
        dot={{ stroke: colors.green, strokeWidth }}
        strokeWidth={strokeWidth}
        isAnimationActive={false}
      />

      <Line
        type="linear"
        dataKey="rain_in_total"
        stroke={"transparent"}
        dot={false}
        strokeWidth={strokeWidth}
        isAnimationActive={false}
      />
    </LineChart>
  );
};

export default RainChart;
