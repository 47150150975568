import React from "react";
import { Flex, Text, Box } from "@chakra-ui/core";
import { colors } from "@uikit/theme";

const CustomLegend = ({ content }) => (
  <Flex
    flex={1}
    px={4}
    py={1}
    mx={12}
    backgroundColor={colors.lightGray}
    borderRadius={8}
    justifyContent="center"
  >
    {content.map((item) => (
      <Flex alignItems="center" px={2}>
        <Box h={2} w={2} borderRadius={100} backgroundColor={item.color} />
        <Text px={2} fontWeight="bold" fontSize={"0.9em"} textAlign="center">
          {item.label}
        </Text>
      </Flex>
    ))}
  </Flex>
);

export default CustomLegend;
