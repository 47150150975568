import React from "react";
import { Flex, Image } from "@chakra-ui/core";
import { useSpring, animated } from "react-spring";
import CompassBodyImage from "@assets/img/compass-body.webp";
import CompassArrow from "@assets/img/compass-arrow.jpg";

const COMPASS_SIZE = 300;
const COMPASS_ORBIT = COMPASS_SIZE - COMPASS_SIZE / 2;

const ARROW_SIZE = 30;

const CompassOrbit = ({ direction }) => {
  const props = useSpring({ transform: `rotate(${direction}deg)` });

  return (
    <Flex
      w={COMPASS_SIZE}
      h={COMPASS_SIZE}
      justify="center"
      align="center"
      position="absolute"
    >
      <animated.div style={props}>
        <Flex
          w={COMPASS_ORBIT}
          h={COMPASS_ORBIT}
          borderRadius={100}
          position="relative"
          justify="center"
        >
          <Image
            src={CompassArrow}
            h={ARROW_SIZE}
            w={ARROW_SIZE}
            transform={"rotate(-90deg)"}
            position="absolute"
          />
        </Flex>
      </animated.div>
    </Flex>
  );
};

const Compass = ({ direction }) => {
  return (
    <Flex marginY="32px">
      <Image
        src={CompassBodyImage}
        height={COMPASS_SIZE}
        width={COMPASS_SIZE}
      />
      <CompassOrbit direction={direction} />
    </Flex>
  );
};

Compass.defaultProps = {
  direction: 0,
};

export default Compass;
