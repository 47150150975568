import React from "react";
import {
  Box,
  Heading,
  Flex,
  Link,
  Image,
  Divider,
  useDisclosure,
} from "@chakra-ui/core";
import { colors } from "@uikit/theme";
import CoralLogoImage from "@assets/img/logo.webp";
import HeaderImage from "@assets/img/header.webp";
import DrawerMenu from "./Drawer";
import navItems from "./items";

const textShadow = `0px 0px 4px ${colors.shadow}`;

const MenuItem = ({ link, children }) => (
  <Link
    fontWeight="bold"
    fontSize={"13px"}
    mt={{ base: 4, md: 0 }}
    display="block"
    href={link}
    textShadow={textShadow}
  >
    {children}
  </Link>
);

const NavMenu = ({ onDrawerToggle }) => (
  <Flex
    as="nav"
    align="center"
    justify={{ lg: "center", xl: "space-between" }}
    wrap="wrap"
    color="white"
    marginBottom={{ xs: "12px", lg: 0 }}
  >
    <Box display={{ xs: "block", lg: "none" }} onClick={onDrawerToggle}>
      <svg
        fill="white"
        width={"16px"}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
      </svg>
    </Box>

    <Flex paddingBottom={{ lg: "14px", xl: 0 }} margin={{ xs: "auto", lg: 0 }}>
      <Image
        src={CoralLogoImage}
        w={{ xs: "280px", lg: "auto" }}
        objectFit="contain"
      />
    </Flex>

    <Box
      display={{ xs: "none", lg: "flex" }}
      width={{ sm: "full", lg: "1024px" }}
      alignItems="center"
      justifyContent="space-between"
    >
      {navItems.map(({ value, link }, index) => (
        <MenuItem key={index} link={link}>
          {value}
        </MenuItem>
      ))}
    </Box>
  </Flex>
);

const Header = ({ title, breadcrumbs }) => {
  const {
    isOpen: isDrawerOpen,
    onClose: onDrawerClose,
    onToggle: onDrawerToggle,
  } = useDisclosure();

  return (
    <Flex position="relative">
      <Image w="100%" src={HeaderImage} display={{ xs: "none", lg: "block" }} />

      <DrawerMenu isOpen={isDrawerOpen} onClose={onDrawerClose} />

      <Flex
        direction="column"
        w="100%"
        h="100%"
        position={{ xs: "static", lg: "absolute" }}
        top="0"
        left="0"
      >
        <Flex
          direction="column"
          padding={{ xs: "8px 18px", md: "0px 45px" }}
          bg={{
            xs: colors.irishGreen,
            lg: "transparent",
          }}
          flex={1}
        >
          <NavMenu onDrawerToggle={onDrawerToggle} />
          <Flex
            alignItems="center"
            justifyContent="center"
            flex={1}
            color="white"
          >
            <Flex direction="column" alignItems="center">
              <Heading
                textAlign="center"
                fontSize={{ xs: "28px", lg: "2em", xl: "2.4em" }}
                textShadow={textShadow}
              >
                {title}
              </Heading>

              <Box
                display={{ xs: "none", lg: "flex" }}
                flexDirection="column"
                alignItems="center"
              >
                <Divider
                  w="200px"
                  borderColor={colors.yellow}
                  borderWidth={2}
                  opacity={1}
                  marginY={"12px"}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

Header.defaultProps = {
  title: "NGEANGES WEATHER STATION",
};

export default Header;
