import React from "react";
import { Button } from "@chakra-ui/core";
import { colors } from "./theme";

const CustomButton = ({ selected, ...props }) => (
  <Button
    color={selected ? "#fff" : null}
    backgroundColor={selected ? colors.irishGreen : null}
    borderRadius={100}
    _focus={{
      boxShadow: "none",
    }}
    _hover={{
      backgroundColor: selected ? colors.bayGreen : "transparent",
    }}
    {...props}
  />
);

export default CustomButton;
