import React from "react";
import { Flex, Text, Box } from "@chakra-ui/core";
import { Button } from "@uikit";
import { colors } from "@uikit/theme";

const Error = ({ message, onClickButton }) => (
  <Flex alignItems="center" flexDirection="column">
    <Text color={colors.red} fontSize={24} fontWeight="bold">
      {message}
    </Text>
    {onClickButton ? (
      <Box paddingTop={2}>
        <Button selected borderRadius={8} onClick={onClickButton}>
          Retry
        </Button>
      </Box>
    ) : null}
  </Flex>
);

export default Error;
