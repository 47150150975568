import React from "react";
import { Line } from "recharts";
import { LineChart } from "@uikit";
import { colors } from "@uikit/theme";
import pick from "lodash/pick";

const TemperatureChart = ({ data, strokeWidth, timeframe, loading }) => {
  const temperatureData = data.records.map((r) =>
    pick(r, ["ts", "ts_month", "ts_year", "airtemp_c_avg", "airtemp_f_avg"])
  );

  const cToF = (c) => (c * (9 / 5) + 32).toFixed(1);

  const labelMap = {
    airtemp_c_avg: "Air Temp. avg (°C)",
    airtemp_f_avg: "Air Temp. avg (°F)",
  };

  const yAxisOffset = 0.5;

  const noAirTempCAvgInData = temperatureData
    ? temperatureData.every((r) => r.airtemp_c_avg === null)
    : [];

  return (
    <LineChart
      title="Average Temperature"
      data={temperatureData}
      labelMap={labelMap}
      timeframe={timeframe}
      leftYAxisLabel={"°C"}
      rightYAxisLabel={"°F"}
      leftYAxisDataKey={
        !noAirTempCAvgInData ? "airtemp_c_avg" : "airtemp_f_avg"
      }
      rightYAxisDataKey={
        !noAirTempCAvgInData ? "airtemp_c_avg" : "airtemp_f_avg"
      }
      leftYTickFormatter={(tick) => tick.toFixed(1)}
      rightYTickFormatter={cToF}
      domain={[`dataMin - ${yAxisOffset}`, `dataMax + ${yAxisOffset}`]}
      legendContent={[
        {
          color: colors.red,
          label: "Average Temperature",
        },
      ]}
      loading={!data || loading}
    >
      <Line
        type="linear"
        dataKey="airtemp_c_avg"
        stroke={colors.red}
        dot={{ stroke: colors.red, strokeWidth }}
        strokeWidth={strokeWidth}
        isAnimationActive={false}
      />

      <Line
        type="linear"
        dataKey="airtemp_f_avg"
        stroke="transparent"
        dot={false}
        strokeWidth={strokeWidth}
        isAnimationActive={false}
      />
    </LineChart>
  );
};

TemperatureChart.defaultProps = {
  data: {
    records: [],
  },
  timeframe: "day",
};

export default TemperatureChart;
