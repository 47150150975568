import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  Box,
  Link,
  Divider,
} from "@chakra-ui/core";
import { colors } from "@uikit/theme";
import navItems from "./items";

const DrawerItem = ({ value, link }) => (
  <Box>
    <Link color={colors.white} padding="16px" href={link} fontWeight="bold">
      {value}
    </Link>
    <Divider />
  </Box>
);

const DrawerMenu = ({ isOpen, onClose }) => {
  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent bg={colors.irishGreen}>
        <DrawerCloseButton color={colors.white} />
        <DrawerBody paddingX={0} paddingTop={"62px"}>
          {navItems.map((props, index) => (
            <DrawerItem key={index} {...props} />
          ))}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerMenu;
