import React from "react";
import { Line } from "recharts";
import { LineChart } from "@uikit";
import { colors } from "@uikit/theme";
import pick from "lodash/pick";

const TotalSolarRadiationChart = ({
  data,
  strokeWidth,
  timeframe,
  loading,
}) => {
  const solarRadiationData = data.records.map((r) =>
    pick(r, ["ts", "ts_month", "ts_year", "solarrad_kjm2_total"])
  );

  const labelMap = {
    solarrad_kjm2_total: "Solar radiation (kJ/㎡)",
  };

  return (
    <LineChart
      title="Total Solar Radiation"
      data={solarRadiationData}
      labelMap={labelMap}
      timeframe={timeframe}
      leftYAxisLabel={"KiloJoules/㎡"}
      rightYAxisLabel={""}
      leftYAxisDataKey={"solarrad_kjm2_total"}
      rightYAxisDataKey={"solarrad_kjm2_total"}
      legendContent={[{ color: colors.gold, label: "Total Solar Radiation" }]}
      leftYTickFormatter={(tick) => Number(tick).toLocaleString()}
      rightYTickFormatter={(tick) => Number(tick).toLocaleString()}
      loading={loading}
    >
      <Line
        type="linear"
        dataKey="solarrad_kjm2_total"
        stroke={colors.gold}
        dot={{ stroke: colors.gold, strokeWidth }}
        strokeWidth={strokeWidth}
        isAnimationActive={false}
      />
    </LineChart>
  );
};

TotalSolarRadiationChart.defaultProps = {
  data: {
    records: [],
  },
  timeframe: "day",
};

export default TotalSolarRadiationChart;
