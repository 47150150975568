import React from "react";
import {
  Box,
  AspectRatioBox,
  Flex,
  Image,
  Text,
  Link,
  Divider,
} from "@chakra-ui/core";
import { colors } from "@uikit/theme";
import CanadaImage from "@assets/img/canada.jpg";
import BookImage from "@assets/img/download-book.jpg";
import FBImage from "@assets/img/fb.png";

const FooterText = (props) => (
  <Text
    color={colors.white}
    fontSize="0.9em"
    textAlign="center"
    {...props}
  ></Text>
);

let getYear = () => {
    let currentYear = new Date().getFullYear();
    return currentYear;
};

const FooterTextLink = ({ children }) => (
  <Link color="#e2d62d">{children}</Link>
);

const BookLink = () => (
  <Link
    isExternal
    href="https://coralreefpalau.org/marine-environments-of-palau/"
  >
    <Image src={BookImage} />
  </Link>
);

const FBLink = () => (
  <Link
    href="https://www.facebook.com/CoralReefResearchFoundation/"
    display="flex"
    alignItems="center"
    justifyContent="center"
    backgroundColor="#fff"
    width="60px"
    height="60px"
    borderRadius="2px"
    marginX="30px"
    marginY="16px"
    isExternal
  >
    <Image src={FBImage} />
  </Link>
);

const DonateLink = () => (
  <Link
    href="https://coralreefpalau.org/contact/#donate"
    color="#333132"
    fontSize="13px"
    height="45px"
    lineHeight="45px"
    fontWeight={800}
    backgroundColor="#e2d62d"
    letterSpacing="1.5px"
    borderRadius="2px"
    padding="0px 24px"
    textDecoration="none"
    _hover={{
      textDecoration: "none",
    }}
    isExternal
  >
    DONATE
  </Link>
);

const FooterImageLinks = () => (
  <Flex direction={["column", "row"]} marginTop="50px" alignItems="center">
    <BookLink />
    <FBLink />
    <DonateLink />
  </Flex>
);

const Footer = () => (
  <Flex direction="column" backgroundColor={colors.irishGreen}>
    <AspectRatioBox maxW="100%" ratio={16 / 9}>
      <Box
        as="iframe"
        title="coral"
        src="https://www.youtube.com/embed/1TxWUEMRSc8"
        allowFullScreen
      />
    </AspectRatioBox>

    <Flex padding="72px 0px" justifyContent="center">
      <Flex direction="column">
        <FooterText marginY="6px">
          Coral Reef Research Foundation Box 1765, Koror, PW 96940, PALAU
        </FooterText>
        <FooterText>
          Telephone: +680-488-5255 | Facsimile: +680-488-5513 |{" "}
          <FooterTextLink
            isExternal
            href="mailto:crrfpalau@gmail.com?Subject=Hello"
          >
            Email Us
          </FooterTextLink>
        </FooterText>
        <FooterImageLinks />
      </Flex>
    </Flex>

    <Divider color="#39ab94" margin="0px" />

    <Flex
      direction={["column", "row"]}
      alignItems="center"
      justifyContent="center"
      paddingY="32px"
    >
        <FooterText>&copy; {getYear()} Coral Reef Research Foundation (CRRF)</FooterText>
        <FooterText display={{ xs: "none", md: "block" }} marginX="4px">&#124;</FooterText>
        <FooterText>Web interface supported by:&nbsp;</FooterText>
        <Link isExternal color="#fff" href="https://whitelabel.ltd">Whitelabel Digital</Link>
        <FooterText>&nbsp;and&nbsp;</FooterText>
      <Image
        src={CanadaImage}
        marginY="8px"
        width="270px"
        h="21px"
        paddingLeft="4px"
      />
    </Flex>
  </Flex>
);

export default Footer;
