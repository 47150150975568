import React from "react";
import { useQuery } from "react-query";
import { Container, ResponsiveIFrame, Error } from "@uikit";
import { Heading, Text, Flex, Link, Skeleton } from "@chakra-ui/core";
import { FiWind, FiCloudRain, FiThermometer, FiSun } from "react-icons/fi";
import { GiSpeedometer } from "react-icons/gi";
import Compass from "./Compass";
import { getCurrentData, parseCurrentData } from "./api";
import { colors } from "@uikit/theme";
import { format, parse } from "date-fns";

const CurrentWeatherContainer = ({ children }) => (
  <Container
    maxH={{ xs: null, xl: "920px" }}
    padding={{ xs: "16px 0px 8px 0px", xl: "32px" }}
    marginBottom="20px"
    flexWrap="wrap"
    alignContent="space-around"
  >
    {children}
  </Container>
);

const MetricItemContainer = ({ children, ...props }) => (
  <Flex paddingY="8px" {...props}>
    {children}
  </Flex>
);

const MetricItemTitle = ({ children, ...props }) => (
  <Text fontSize="0.9em" as="b" textAlign="center" py={1}>
    {children}
  </Text>
);

const MetricItemColoredText = ({ children }) => (
  <Text fontSize="0.9em" color="#319795" as="b" textAlign="center">
    {children}
  </Text>
);

const MetricItem = ({ icon, title, bottom }) => (
  <MetricItemContainer>
    {icon}
    <Flex flexDirection="column" paddingX="12px">
      <MetricItemTitle>{title}</MetricItemTitle>
      {bottom}
    </Flex>
  </MetricItemContainer>
);

const WindyWidget = () => (
    <MetricItemContainer flexDirection="column">
        <div style={{textAlign: 'center'}}>
            <Link
                textDecoration="underline"
                color="#1a202c"
                isExternal
                href="https://www.windy.com/7.263/134.437?6.887,134.608,8"
            >
                Current Wx state in Palau, Windy.com
            </Link>
        </div>
        <ResponsiveIFrame
            src="https://embed.windy.com/embed2.html?lat=7.263&lon=134.437&detailLat=-34.922&detailLon=-57.954&width=650&height=450&zoom=9&level=surface&overlay=wind&product=ecmwf&menu=&message=true&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1"
            scrolling={false}
        />
    </MetricItemContainer>
);

const TidesWidget = () => (
    <MetricItemContainer flexDirection="column">
        <div style={{textAlign: 'center'}}>
            <Link
                textDecoration="underline"
                color="#319795"
                isExternal
                href="https://www.tidetablechart.com/tides/hightide_lowtide/32318/Koror"
            >
                Tides/Moon phases in Palau
            </Link>
        </div>
        <ResponsiveIFrame
            src="https://www.tidetablechart.com/tides/hightide_lowtide/32318/Koror"
            scrolling={true}
        />
        {/* <iframe
      src="https://www.timeanddate.com/moon/phases/palau/koror"
      width={500}
      height={500}
      sandbox
    /> */}
    </MetricItemContainer>
);

const CurrentWeatherCard = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    "current",
    getCurrentData
  );

  if (isLoading) {
    return (
      <Container
        maxH={{ xs: null, xl: "920px" }}
        padding={{ xs: "16px 0px 8px 0px", xl: "32px" }}
        marginBottom="20px"
      >
        <Skeleton
          h={{ xs: null, xl: "920px" }}
          w="100%"
          borderRadius={8}
          colorStart={colors.cardWhite}
          colorEnd={colors.gray}
        />
      </Container>
    );
  }

  if (isError) {
    return (
      <CurrentWeatherContainer>
        <Error
          message={"There was an error fetching the current weather data"}
          onClickButton={refetch}
        />
      </CurrentWeatherContainer>
    );
  }

  const {
    ts,
    totalRainMm,
    temperatureC,
    temperatureF,
    solarRadiationKmj2,
    barometricPressureMbar,
    relativeHumidity,
    windDirectionDeg,
    windDirectionDir,
    windSpeedKtsMax,
    windSpeedKtsAvg,
  } = parseCurrentData(data.current);

  const renderWidgets = () => (
    <Flex direction="column" alignItems="center" w={{ base: "-webkit-fill-available", md: "624px" }}>
      <WindyWidget />
      <TidesWidget />
    </Flex>
  );

  const renderInfo = () => (
    <Flex direction="column" alignItems="center" justifyContent="space-between" w={{ base: "-webkit-fill-available", md: "unset" }}>
      <Flex flexDirection="column" alignItems="center">
        <Heading fontSize={{ xs: "24px", xl: "2em" }}>
          Current Rock Island Weather
        </Heading>
        <Text>Ngeanges Island, Palau</Text>
        <Link
          textDecoration="underline"
          color="#319795"
          isExternal
          href="https://www.google.com/maps/place/7.209506,134.371286"
        >
          7° 12.570' N, 134° 22.277' E
        </Link>
        <MetricItemColoredText>
          At Palau{" "}
          {format(
            parse(ts, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date()),
            "HH:MM"
          )}{" "}
          Standard Time
        </MetricItemColoredText>
        <Text fontSize={"0.9em"}>(GMT +9)</Text>
      </Flex>

      <MetricItem
        icon={<FiWind size="32px" />}
        title="WIND"
        bottom={
          <Flex direction="column">
            <MetricItemColoredText>{`Avg. Wind ${windSpeedKtsAvg} kts ${windDirectionDir}`}</MetricItemColoredText>
            <MetricItemColoredText>{`Gusts ${windSpeedKtsMax} kts`}</MetricItemColoredText>
          </Flex>
        }
      />

      <Compass direction={windDirectionDeg} />

      <MetricItem
        icon={<FiCloudRain size="32px" />}
        title="RAIN- last hour"
        bottom={
          <MetricItemColoredText>{`${(totalRainMm / 25.4).toFixed(
            2
          )} inches/${totalRainMm} mm`}</MetricItemColoredText>
        }
      />

      <MetricItem
        icon={<FiThermometer size="32px" />}
        title="AVG TEMPERATURE/REL HUMIDITY"
        bottom={
          <Flex>
            <Flex flex={2} justifyContent="center">
              <MetricItemColoredText>{`${temperatureF} °F/${temperatureC} °C`}</MetricItemColoredText>
            </Flex>
            <Flex flex={1} justifyContent="center">
              <MetricItemColoredText>{`${relativeHumidity}%`}</MetricItemColoredText>
            </Flex>
          </Flex>
        }
      />

      <MetricItem
        icon={<FiSun size="32px" />}
        title="SOLAR RADIATION- TOTAL (last hour)"
        bottom={
          <MetricItemColoredText>{`${Number(
            solarRadiationKmj2
          ).toLocaleString()} kJ/m²`}</MetricItemColoredText>
        }
      />

      <MetricItem
        icon={<GiSpeedometer size="32px" />}
        title="BAROMETRIC PRESSURE"
        bottom={
          <MetricItemColoredText>{`${(barometricPressureMbar / 33.864).toFixed(
            2
          )} in Hg/${barometricPressureMbar} mb`}</MetricItemColoredText>
        }
      />
    </Flex>
  );

  return (
    <CurrentWeatherContainer>
      {renderInfo()}
      {renderWidgets()}
    </CurrentWeatherContainer>
  );
};

export default CurrentWeatherCard;
