import React from "react";
import { Flex, Text, Link, ThemeProvider, CSSReset } from "@chakra-ui/core";
import { BaseLayout } from "@uikit";
import { colors } from "@uikit/theme";
import CurrentWeather from "./CurrentWeather";
import History from "./History";

const LightTealBox = ({ children, ...props }) => (
  <Flex
    bg={colors.irishGreen}
    justifyContent={"center"}
    padding="28px"
    flex={1}
    {...props}
  >
    {children}
  </Flex>
);

const Main = () => {
  return (
    <ThemeProvider>
      <CSSReset />
      <BaseLayout>
        <LightTealBox color="#fff">
          <Text fontWeight={"500"} fontSize={"0.9em"} textAlign="center">
            View live weather, refreshed hourly, from the{" "}
            <Link
              fontWeight="bold"
              href="https://coralreefpalau.org/research/oceanographyweather/meteorological-monitoring/"
              isExternal
              textDecoration="underline"
            >
              Ngeanges tower weather station
            </Link>{" "}
            in the rock islands of Palau, run by the Coral Reef Research
            Foundation.
          </Text>
        </LightTealBox>
        <Flex
          p={{ xs: "0px 0px", xl: "40px 144px" }}
          flexDirection="column"
          justifyContent="center"
          backgroundColor={colors.cardWhite}
        >
          <CurrentWeather />
          <History />
        </Flex>
        <LightTealBox flexDirection="column" color="#fff">
          <Text fontWeight={"500"} fontSize={"0.9em"} paddingBottom={4}>
            The autonomous{" "}
            <Link
              href="https://coralreefpalau.org/research/oceanographyweather/meteorological-monitoring/"
              fontWeight="bold"
              isExternal
              textDecoration="underline"
            >
              CRRF tower weather station
            </Link>{" "}
            was established in the central rock islands of Palau in 2007. The
            station is on a 40ft (12m) tower on top of a 100ft (30m) rock island
            and provides localized live weather for the public, including the
            tourism industry, as well as longer term records for weather
            influencing nearby{" "}
            <Link
              fontWeight="bold"
              href="https://coralreefpalau.org/research/marine-lakes/jellyfish-lake/"
              isExternal
              textDecoration="underline"
            >
              Jellyfish Lake.
            </Link>{" "}
          </Text>
          <Text fontWeight={"500"} fontSize={"0.9em"}>
            While we perform tests to ensure data quality control, we highly
            recommend that the user perform their own data quality control if
            they intend to use the data for their own research or other
            projects.  Through quality control checks, we have deleted data
            recorded with faulty instruments, therefore some months or years may
            be missing data. Users can request historical data and more
            information by emailing crrfpalau@gmail.com. Please indicate the
            purpose and preferred weather variable(s) and dates.
          </Text>
        </LightTealBox>
      </BaseLayout>
    </ThemeProvider>
  );
};

export default Main;
