import React from "react";
import { Box } from "@chakra-ui/core";

const HEIGHT = 520;
const WIDTH = 728;

const ResponsiveIFrame = ({ src, scrolling, disableMouse }) => (
  <Box
    transform={{
      xs: "scale(0.5)",
      md: "scale(0.75)",
      lg: "scale(1)",
      xl: "scale(0.75)",
    }}
    margin={{ xs: "-120px", md: "-64px", lg: "0px", xl: "-64px" }}
    position="relative"
  >
    <iframe
      title="iFrame"
      width={WIDTH}
      height={HEIGHT}
      src={src}
      frameborder="0"
      scrolling={scrolling ? "yes" : "no"}
      sandbox
    ></iframe>
    {disableMouse && (
      <Box w={WIDTH} h={HEIGHT} position="absolute" top={0} zIndex={1} />
    )}
  </Box>
);

export default ResponsiveIFrame;
