import React from "react";
import { Flex, Heading, Box } from "@chakra-ui/core";
import { DatePicker, Button } from "@uikit";
import { colors } from "@uikit/theme";
import { timeframeLabelMap } from "@constants";

const Filters = ({ date, timeframe, setDate, setTimeframe }) => {
  const inputLabelFormatMap = {
    day: "MMM d, yyyy",
    month: "MMM, yyyy",
    year: "yyyy",
  };

  const renderLeft = () => (
    <Flex alignItems="center" justifyContent="center" flex={1}>
      <Heading size="md">{timeframeLabelMap[timeframe]} Summary</Heading>
    </Flex>
  );

  const renderCenter = () => (
    <Flex
      flexDirection={{ xs: "column", md: "row" }}
      alignItems="center"
      justifyContent={{
        xs: "center",
        md: timeframe !== "all" ? "space-between" : "center",
      }}
      flex={1}
    >
      <Flex paddingY={{ xs: "16px", md: 0 }}>
        {timeframe !== "all" ? (
          <DatePicker
            dateFormat={inputLabelFormatMap[timeframe]}
            selected={date}
            onChange={setDate}
            showYearDropdown={timeframe === "day"}
            showMonthDropdown={timeframe === "day"}
            showMonthYearPicker={timeframe === "month"}
            showYearPicker={timeframe === "year"}
            maxDate={new Date()}
            minDate={new Date(2007, 0)}
          />
        ) : null}
      </Flex>
      <Box paddingLeft={{ xs: 0, md: timeframe !== "all" ? 4 : 0 }}>
        <Flex
          borderRadius={100}
          justifyContent={"space-between"}
          backgroundColor={colors.lightGray}
        >
          <Button
            selected={timeframe === "day"}
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
            onClick={() => setTimeframe("day")}
            fontSize={"0.9em"}
          >
            {timeframeLabelMap["day"].toUpperCase()}
          </Button>
          <Button
            selected={timeframe === "month"}
            borderRadius="0px"
            onClick={() => setTimeframe("month")}
            fontSize={"0.9em"}
          >
            {timeframeLabelMap["month"].toUpperCase()}
          </Button>
          <Button
            selected={timeframe === "year"}
            onClick={() => setTimeframe("year")}
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
            borderTopLeftRadius={0}
            borderBottomLeftRadius={0}
            fontSize={"0.9em"}
          >
            {timeframeLabelMap["year"].toUpperCase()}
          </Button>
          <Button
            selected={timeframe === "all"}
            borderTopLeftRadius={0}
            borderBottomLeftRadius={0}
            onClick={() => setTimeframe("all")}
            fontSize={"0.9em"}
          >
            {timeframeLabelMap["all"].toUpperCase()}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );

  const renderRight = () => (
    <Flex display={{ xs: "none", md: "flex" }} flex={1} />
  );

  return (
    <Flex flexWrap="wrap" paddingY="32px">
      {renderLeft()}
      {renderCenter()}
      {renderRight()}
    </Flex>
  );
};

export default Filters;
