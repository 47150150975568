import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Flex, Heading } from "@chakra-ui/core";
import { Container, Error } from "@uikit";
import { usePrevious } from "@lib/hooks";
import {
  BarometricPressureChart,
  HumidityChart,
  RainChart,
  TotalSolarRadiationChart,
  AverageSolarRadiationChart,
  TemperatureChart,
  WindChart,
  WindDirectionChart,
} from "./Charts";
import Filters from "./Filters";
import { getHistoryData } from "./api";
import {
  addDays,
  subDays,
  addMonths,
  addYears,
  startOfMonth,
  startOfYear,
} from "date-fns";

const HistoryCard = () => {
  const [timeframe, setTimeframe] = useState("day");
  const [date, setDate] = useState(new Date());

  const prevTimeframe = usePrevious(timeframe);

  useEffect(() => {
    if (prevTimeframe !== timeframe) {
      if (timeframe === "month") {
        setDate(startOfMonth(date));
      }
      if (timeframe === "year") {
        setDate(startOfYear(date));
      }
    }
  }, [date, prevTimeframe, timeframe]);

  const { data, isLoading, error } = useQuery(
    ["history", date, timeframe],
    () => getHistoryData(date, getEndDate(date, timeframe), timeframe)
  );

  const getEndDate = (date, timeframe) => {
    switch (timeframe) {
      case "day":
        return addDays(date, 1);

      case "month":
        return date.getMonth() === new Date().getMonth() &&
          date.getYear() === new Date().getYear()
          ? addMonths(date, 1)
          : subDays(addMonths(date, 1), 1);

      case "year":
        return subDays(addYears(date, 1), 1);

      default:
        return date;
    }
  };

  const renderSummary = () => (
    <Flex flexDirection="column">
      <Heading textAlign="center" fontSize={"2em"}>
        Rock Island Weather: Observation History
      </Heading>
      <Filters
        date={date}
        timeframe={timeframe}
        setDate={setDate}
        setTimeframe={setTimeframe}
      />
    </Flex>
  );

  const renderHistory = () => {
    const chartProps = {
      data,
      strokeWidth: 3,
      timeframe,
      loading: isLoading,
    };

    if (error) {
      return (
        <Error message={"There was an error fetching the history data."} />
      );
    }

    if (!isLoading && !chartProps.data?.records?.length) {
      return (
        <Error
          message={
            "There's no data for the range of dates you specified. Try selecting different dates."
          }
        />
      );
    }

    return (
      <Flex flexDirection="column">
        <WindChart {...chartProps} />
        <WindDirectionChart {...chartProps} />
        <RainChart {...chartProps} />
        <TemperatureChart {...chartProps} />
        <HumidityChart {...chartProps} />
        <BarometricPressureChart {...chartProps} />
        <TotalSolarRadiationChart {...chartProps} />
        <AverageSolarRadiationChart {...chartProps} />
      </Flex>
    );
  };

  return (
    <Container padding={{ xs: "16px 0px", md: "32px" }}>
      {renderSummary()}
      {renderHistory()}
    </Container>
  );
};

export default HistoryCard;
