import React from "react";
import { Line } from "recharts";
import { LineChart } from "@uikit";
import { colors } from "@uikit/theme";
import pick from "lodash/pick";

const AverageSolarRadiation = ({ data, strokeWidth, timeframe, loading }) => {
  const solarRadiationData = data.records.map((r) =>
    pick(r, ["ts", "ts_month", "ts_year", "solarrad_w_avg"])
  );

  const labelMap = {
    solarrad_w_avg: "Solar radiation (W/㎡)",
  };

  return (
    <LineChart
      title="Average Solar Radiation"
      data={solarRadiationData}
      labelMap={labelMap}
      timeframe={timeframe}
      leftYAxisLabel={"Watts/㎡"}
      rightYAxisLabel={""}
      leftYAxisDataKey={"solarrad_w_avg"}
      rightYAxisDataKey={"solarrad_w_avg"}
      verticalLabels={true}
      legendContent={[
        { color: colors.orange, label: "Average Solar Radiation" },
      ]}
      leftYTickFormatter={(tick) => Number(tick).toLocaleString()}
      rightYTickFormatter={(tick) => Number(tick).toLocaleString()}
      loading={loading}
    >
      <Line
        type="linear"
        dataKey="solarrad_w_avg"
        stroke={colors.orange}
        dot={{ stroke: colors.orange, strokeWidth }}
        strokeWidth={strokeWidth}
        isAnimationActive={false}
      />
    </LineChart>
  );
};

AverageSolarRadiation.defaultProps = {
  data: {
    records: [],
  },
  timeframe: "day",
};

export default AverageSolarRadiation;
