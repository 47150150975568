import React from "react";
import PropTypes from "prop-types";
import useMobileDetect from "use-mobile-detect-hook";
import { Flex, Heading, Skeleton, Box } from "@chakra-ui/core";
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from "recharts";
import { Tooltip as CustomTooltip, Legend as CustomLegend } from "@uikit";
import { colors } from "@uikit/theme";
import parse from "date-fns/parse";
import format from "date-fns/format";

const CustomLineChart = ({
  data,
  title,
  labelMap,
  timeframe,
  leftYTicks,
  rightYTicks,
  leftYTickFormatter,
  rightYTickFormatter,
  leftYAxisLabel,
  rightYAxisLabel,
  leftYAxisDataKey,
  rightYAxisDataKey,
  domain,
  legendContent,
  loading,
  height,
  children: lines,
}) => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  const timeframeMap = {
    day: "ts",
    month: "ts",
    year: "ts_month",
    all: "ts_year",
  };

  const getTooltipTitle = (payload) => {
    const formats = {
      day: "haa, do 'of' MMM'",
      month: "do 'of' MMM',' yyyy",
      year: "MMM',' yyyy",
      all: "yyyy",
    };

    const { ts, ts_month, ts_year } = payload.payload;

    let date = new Date();

    switch (timeframe) {
      case "day":
        if (ts) {
          const dt = new Date(ts);
          date = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
        } else {
          date = new Date();
        }
        break;

      case "month":
        date = ts ? parse(ts, "yyyy-MM-dd", new Date()) : new Date();
        break;

      case "year":
        date =
          ts_month && ts_year ? new Date(ts_year, ts_month - 1) : new Date();
        break;

      case "all":
        date = ts_year ? new Date(ts_year, new Date().getMonth()) : new Date();
        break;

      default:
        break;
    }

    return format(date, formats[timeframe]);
  };

  const getTooltipContent = (payload) => {
    // Removing the duplicate and creating an object to access directly.
    const data = payload
      ? payload.reduce(
          (obj, { name, value }) => ({ ...obj, [name]: value.toFixed(2) }),
          {}
        )
      : {};

    return Object.entries(data).map(([key, value]) => ({
      label: `${labelMap[key]}: `,
      value,
    }));
  };

  const xAxisTickFormatter = (tick) => {
    const formats = {
      day: "haa",
      month: "MMM d",
      year: "MMM",
      all: "yyyy",
    };

    switch (timeframe) {
      case "day":
        const date = new Date(tick);
        const dateWithoutTimezone = new Date(
          date.valueOf() + date.getTimezoneOffset() * 60 * 1000
        );
        return format(dateWithoutTimezone, formats[timeframe]);

      case "month":
        return format(
          parse(tick, "yyyy-MM-dd", new Date()),
          formats[timeframe]
        );

      case "year":
        // The year here is not that relevant
        return format(new Date(new Date().getYear(), tick - 1), formats[timeframe]);

      default:
        return tick;
    }
  };

  const renderLegend = () => {
    return (
      <Box marginTop={"32px"}>
        <CustomLegend content={legendContent} />
      </Box>
    );
  };

  const yAxisLabelOffset = isMobile ? 16 : -32;
  const horizontalMargin = 64;
  const yAxisTick = { fontSize: isMobile ? "0.7em" : "1em" };
  const xAxisTick = { fontSize: isMobile ? "0.8em" : "1em" };

  return (
    <Flex direction="column">
      <Heading size="md" px="16px" py="24px">
        {title}
      </Heading>
      {loading ? (
        <Box
          mx={{
            xs: `${horizontalMargin}px`,
            md: `${horizontalMargin + Math.abs(yAxisLabelOffset)}px`,
          }}
        >
          <Skeleton
            colorStart={colors.cardWhite}
            colorEnd={colors.gray}
            borderRadius={8}
            h={height - 64}
          />
          <Skeleton
            colorStart={colors.cardWhite}
            colorEnd={colors.gray}
            borderRadius={8}
            h={"32px"}
            marginTop={"32px"}
          />
        </Box>
      ) : (
        <ResponsiveContainer width="100%" height={height}>
          <LineChart
            data={data}
            margin={
              !isMobile
                ? { left: horizontalMargin, right: horizontalMargin }
                : { left: 0, right: 0 }
            }
          >
            <CartesianGrid horizontal={true} vertical={true} />
            <XAxis
              dataKey={timeframeMap[timeframe]}
              tickFormatter={xAxisTickFormatter}
              tick={xAxisTick}
            />
            <YAxis
              ticks={leftYTicks}
              dataKey={leftYAxisDataKey}
              tickFormatter={
                leftYTickFormatter ? (tick) => leftYTickFormatter(tick) : null
              }
              domain={domain}
              tick={yAxisTick}
            >
              <Label
                value={leftYAxisLabel}
                position="insideLeft"
                angle={-90}
                offset={yAxisLabelOffset}
              />
            </YAxis>
            <YAxis
              ticks={rightYTicks}
              dataKey={rightYAxisDataKey}
              orientation="right"
              tickFormatter={
                rightYTickFormatter ? (tick) => rightYTickFormatter(tick) : null
              }
              domain={domain}
              tick={yAxisTick}
              yAxisId="right"
            >
              <Label
                value={rightYAxisLabel}
                position="insideRight"
                angle={90}
                offset={yAxisLabelOffset}
              />
            </YAxis>
            <Legend content={renderLegend} />
            <Tooltip
              content={({ active, payload }) => {
                return active && payload && payload[0] ? (
                  <CustomTooltip
                    title={getTooltipTitle(payload[0])}
                    content={getTooltipContent(payload)}
                  />
                ) : null;
              }}
            />
            {lines}
          </LineChart>
        </ResponsiveContainer>
      )}
    </Flex>
  );
};

CustomLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
  labelMap: PropTypes.shape({}).isRequired,
  timeframe: PropTypes.string.isRequired,
  rightYTickFormatter: PropTypes.func.isRequired,
  leftYAxisLabel: PropTypes.string.isRequired,
  rightYAxisLabel: PropTypes.string.isRequired,
  legendContent: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

CustomLineChart.defaultProps = {
  height: 380,
};

export default CustomLineChart;
