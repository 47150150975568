import React from "react";
import { Line } from "recharts";
import { LineChart } from "@uikit";
import { colors } from "@uikit/theme";
import pick from "lodash/pick";

const WindChart = ({ data, strokeWidth, timeframe, loading }) => {
  const windData = data.records.map((r) =>
    pick(r, [
      "ts",
      "ts_month",
      "ts_year",
      "windspeed_kt_max",
      "windspeed_kte_avg",
      "windspeed_ms_avg",
      "windspeed_ms_max",
    ])
  );

  const msToKnots = (ms) => (ms * 1.944).toFixed(1);
  const msFormatt = (ms) => ms.toFixed(1);

  const labelMap = {
    windspeed_kt_max: "Wind speed max (kts)",
    windspeed_kte_avg: "Wind speed avg (kts)",
    windspeed_ms_max: "Wind speed max (m/s)",
    windspeed_ms_avg: "Wind speed avg (m/s)",
  };

  const noWindMsMaxInData = windData.every((r) => r.windspeed_ms_max === null);

  return (
    <LineChart
      title="Wind Speed/Gusts"
      data={windData}
      labelMap={labelMap}
      timeframe={timeframe}
      leftYAxisLabel={"m/s"}
      rightYAxisLabel={"Knots"}
      leftYAxisDataKey={
        !noWindMsMaxInData ? "windspeed_ms_max" : "windspeed_ms_avg"
      }
      rightYAxisDataKey={
        !noWindMsMaxInData ? "windspeed_ms_max" : "windspeed_ms_avg"
      }
      legendContent={[
        { color: colors.deepSkyLightBlue, label: "Maximum Wind" },
        { color: colors.solidDarkBlue, label: "Average Wind" },
      ]}
      rightYTickFormatter={msToKnots}
      leftYTickFormatter={msFormatt}
      loading={loading}
    >
      <Line
        type="linear"
        dataKey="windspeed_ms_max"
        stroke={"transparent"}
        dot={{ stroke: colors.deepSkyLightBlue, strokeWidth }}
        strokeWidth={strokeWidth}
        isAnimationActive={false}
      />

      <Line
        type="linear"
        dataKey="windspeed_ms_avg"
        stroke={colors.solidDarkBlue}
        dot={{ stroke: colors.solidDarkBlue, strokeWidth }}
        strokeWidth={strokeWidth}
        isAnimationActive={false}
      />

      <Line
        type="linear"
        dataKey="windspeed_kt_max"
        stroke="none"
        dot={false}
        activeDot={false}
        strokeWidth={strokeWidth}
        isAnimationActive={false}
      />

      <Line
        type="linear"
        dataKey="windspeed_kte_avg"
        stroke="none"
        dot={false}
        activeDot={false}
        strokeWidth={strokeWidth}
        isAnimationActive={false}
      />
    </LineChart>
  );
};

WindChart.defaultProps = {
  data: {
    records: [],
  },
  timeframe: "day",
};

export default WindChart;
