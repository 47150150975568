import React from "react";
import { Flex } from "@chakra-ui/core";

const Container = ({ children, ...props }) => {
  return (
    <Flex
      w="100%"
      backgroundColor="#fff"
      boxShadow="0 0 12px rgba(0, 0, 0, 0.2)"
      borderRadius={8}
      flexDirection="column"
      {...props}
    >
      {children}
    </Flex>
  );
};

export default Container;
