const coralUrl = process.env.REACT_APP_CORAL_URL;

export default [
  { value: "HOME", link: coralUrl },
  { value: "FACILITIES", link: `${coralUrl}facilities/` },
  { value: "RESEARCH", link: `${coralUrl}research/` },
  { value: "EDUCATION", link: `${coralUrl}education/` },
  {
    value: "DATA & PUBLICATIONS",
    link: `${coralUrl}marine-environments-of-palau/`,
  },
  { value: "PROJECTS", link: `${coralUrl}projects/` },
  { value: "OUR TEAM", link: `${coralUrl}our-team/` },
  { value: "CONTACT", link: `${coralUrl}contact/` },
];
