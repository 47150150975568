import React from "react";
import { Line } from "recharts";
import { LineChart } from "@uikit";
import { colors } from "@uikit/theme";
import pick from "lodash/pick";

const BarometricPressureChart = ({ data, strokeWidth, timeframe, loading }) => {
  const barometricPressureData = data.records
    .map((r) => pick(r, ["ts", "ts_month", "ts_year", "airpressure_mbar_avg"]))
    .map((r) => ({
      ...r,
      airpressure_inhg_avg: r.airpressure_mbar_avg / 33.864,
    }));

  const labelMap = {
    airpressure_mbar_avg: "Barometric pressure (mb)",
    airpressure_inhg_avg: "Barometric pressure (in. Hg.)",
  };

  const mbToInhg = (tick) => (tick / 33.864).toFixed(2);

  const yAxisOffset = 0.5;

  const noAirPressureMbarAvgInData = barometricPressureData
    ? barometricPressureData.every((r) => r.airpressure_mbar_avg === null)
    : [];

  return (
    <LineChart
      title="Barometric Pressure"
      data={barometricPressureData}
      labelMap={labelMap}
      timeframe={timeframe}
      leftYAxisLabel={"mb"}
      rightYAxisLabel={"in. Hg"}
      leftYAxisDataKey={
        !noAirPressureMbarAvgInData
          ? "airpressure_mbar_avg"
          : "airpressure_inhg_avg"
      }
      rightYAxisDataKey={
        !noAirPressureMbarAvgInData
          ? "airpressure_mbar_avg"
          : "airpressure_inhg_avg"
      }
      leftYTickFormatter={(tick) => Number(tick).toFixed(2).toLocaleString()}
      rightYTickFormatter={mbToInhg}
      legendContent={[
        {
          color: colors.deepSkyBlue,
          label: "Barometric Pressure",
        },
      ]}
      loading={!data || loading}
      domain={[`dataMin - ${yAxisOffset}`, `dataMax + ${yAxisOffset}`]}
    >
      <Line
        type="linear"
        dataKey="airpressure_mbar_avg"
        stroke={colors.deepSkyBlue}
        dot={{ stroke: colors.deepSkyBlue, strokeWidth }}
        strokeWidth={strokeWidth}
        isAnimationActive={false}
      />

      <Line
        type="linear"
        dataKey="airpressure_inhg_avg"
        stroke="transparent"
        dot={false}
        strokeWidth={strokeWidth}
        isAnimationActive={false}
      />
    </LineChart>
  );
};

BarometricPressureChart.defaultProps = {
  data: {
    records: [],
  },
  timeframe: "day",
};

export default BarometricPressureChart;
