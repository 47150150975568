import { request } from "@lib";

export const getCurrentData = async () =>
  request("GET", "/current", "There was an error");

export const parseCurrentData = (data) => {




  return {
    ts: data.ts,
    totalRainMm: data.rain_mm_total?.toFixed(2),
    temperatureC: data.airtemp_c_avg?.toFixed(2),
    temperatureF: data.airtemp_f_avg?.toFixed(2),
    solarRadiationKmj2: data.solarrad_kjm2_total?.toFixed(2),
    barometricPressureMbar: data.airpressure_mbar_avg?.toFixed(2),
    barometricPressureMmhg: data.airpressure_mmhg_avg?.toFixed(2),
    relativeHumidity: data.relhumidity_pc_max,
    windDirectionDeg: data.winddirection_deg_wvt,
    windDirectionDir: data.winddirection_dir_wvt,
    windSpeedKtsMax: data.windspeed_kt_max?.toFixed(2),
    windSpeedKtsAvg: data.windspeed_kte_avg?.toFixed(2),
    windSpeedMsAvg: data.windspeed_ms_avg,
    windSpeedMsMax: data.windspeed_ms_max,
  };
};