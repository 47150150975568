import { request } from "@lib";
import { format } from "date-fns";

const groupByMap = {
  day: "hour",
  month: "day",
  year: "month",
  all: "year",
};

export const getHistoryData = async (startDate, endDate, timeframe) => {
  try {
    const formatString = "yyyy-MM-dd";
    const fromDate = format(startDate, formatString);
    const toDate = format(endDate, formatString);
    return request(
      "GET",
      `/records?${
        timeframe !== "all" ? `fromDate=${fromDate}&toDate=${toDate}&` : ""
      }groupBy=${groupByMap[timeframe]}`,
      "There was an error getting the history data."
    );
  } catch (e) {
    console.log(e);
  }
};
