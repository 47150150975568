import React from "react";
import DatePicker from "react-datepicker";
import { Button } from "@uikit";
import { colors } from "@uikit/theme";
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePickerInput = ({ value, onClick, ...props }) => (
  <Button
    leftIcon="calendar"
    backgroundColor={colors.irishGreen}
    color="#fff"
    borderRadius={100}
    onClick={onClick}
    _hover={{
      backgroundColor: colors.bayGreen,
    }}
    {...props}
  >
    {value}
  </Button>
);

const CustomDatePicker = (props) => (
  <DatePicker
    customInput={<CustomDatePickerInput {...props.buttonProps} />}
    {...props}
  />
);

export default CustomDatePicker;
